@media (max-width: 576px) {
  .faqs {
      margin-top: 4rem;
      margin-left: 1rem;
      margin-right: 1rem;   
     
    }
    .methoddiv{
      margin-top: 1em;
    }
    
  

    .layeronediv{
      text-align: center;
      width: 100%;
      margin-left: 0em;
      margin-bottom: 3em;
     
    }
    .layeronetitle p {
      text-align: center;
      color: #33AF4F;
      font-size: 24px;
      font-weight: bold;
      height: 1rem;
      /* margin-bottom: 3em; */
    
    
    }
    .layeronetitlewide p {
      text-align: center;
      color: #33AF4F;
      font-size: 24px;
      font-weight: bold;
      height: 1rem;
      /* margin-bottom: 3em; */
      display: none;

    }
    .dropdown{
      height:auto;
      border: solid 2px;
      border-color: gainsboro;
      border-radius: 10px;
      /* width: 100%; */
      padding-top: .8em;
      padding-left:1em;
      margin-bottom: .5em;
      
    
  }

  .dropdownlast{
    height:auto;
    border: solid 2px;
    border-color: gainsboro;
    border-radius: 10px;
    /* width: 100%; */
    padding-top: .8em;
    padding-left:1em;
    margin-bottom: 2em;
  
}
  .mother{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: .5rem;
    /* border-bottom: 2px solid gainsboro; */
    /* background-color: black; */
    
  }
  .spandiv{
    flex-direction: row;
    width: 80%;
    
   
  }
  .widthdiv{
    width: 80%;
    
  }
  

  .imagediv{
    margin-right: .5em;
  
  }
  .dropdownContent{
    margin-top: 1rem;
    margin-right: .5em;
  }
  

  .spanindropdown{
    font-size: 16px;
    font-weight: 600;
    color: #33AF4F;
  }
  
  .dropdownContainer {
    position: relative; /* Set the parent container to relative positioning */
  }
  
  .line {
    margin-top: -1em;
    border-top: 2px solid gainsboro; /* Adjust the color and width as needed */
    width:100%;
    margin-left: -.3em;
    margin-bottom: .5em;
  }
  .descriptiondiv{
    /* background-color: black; */
    font-size: 14px;
   
    margin-left: .2em;
    margin-bottom: .5em;
  }
  .step {
    display: block;
  }
  
  .step2{
    margin-bottom: 1em;
  }
  .step3{
    display: block;
   margin-left: 1em;
  }
  .method3{
    font-weight: bold;
  }

    
  }

  @media (min-width: 577px) and (max-width: 768px) {
    .faqs {
        margin-top: 1rem;
        margin-left: 2rem;
        margin-right: 2rem;   
        /* background-color: black;  */
      }
      .methoddiv{
        margin-top: 1em;
      }
      
  
      .layeronediv{
        text-align: center;
        width: 100%;
        margin-left: 1em;
        margin-bottom: 3em;
        display: none;
       
      }
      .layeronetitle p {
        text-align: center;
        color: #33AF4F;
        font-size: 24px;
        font-weight: bold;
        height: 1rem;
        /* margin-bottom: 3em; */
      }

      .layeronetitlewide p {
        text-align: center;
        color: #33AF4F;
        font-size: 32px;
        font-weight: bold;
        height: 1rem;
        margin-bottom: 2em;
        margin-top: 4em;
      
      
      }
      
      .dropdown{
        height:auto;
        border: solid 2px;
        border-color: gainsboro;
        border-radius: 10px;
      
        padding-top: .8em;
        padding-left:1em;
        margin-bottom: .5em;
        
      
    }
    .dropdownlast{
      height:auto;
      border: solid 2px;
      border-color: gainsboro;
      border-radius: 10px;
    
      padding-top: .8em;
      padding-left:1em;
      margin-bottom: 2em;
    
  }
    .mother{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: .5rem;
      /* border-bottom: 2px solid gainsboro; */
      /* background-color: black; */
      
    }
    .spandiv{
      flex-direction: row;
      width: 80%;
      
     
    }
    .widthdiv{
      width: 80%;
      
    }
    
  
    .imagediv{
      margin-right: .5em;
    
    }
    .dropdownContent{
      margin-top: 1rem;
      margin-right: .5em;
    }
    
  
    .spanindropdown{
      font-size: 18px;
      font-weight: 600;
      color: #33AF4F;
    }
    
    .dropdownContainer {
      position: relative; /* Set the parent container to relative positioning */
    }
    
    .line {
      margin-top: -1em;
      border-top: 2px solid gainsboro; /* Adjust the color and width as needed */
      width:100%;
      margin-left: -.3em;
      margin-bottom: .5em;
    }
    .descriptiondiv{
      /* background-color: black; */
      font-size: 14;
      margin-left: .2em;
      margin-bottom: .5em;
    }
    .step {
      display: block;
      
      
     
    }
    .steps-container {
      width: 80%;
      margin: auto;
    }
    .step2{
      margin-bottom: 1em;
    }
    .step3{
      display: block;
     margin-left: 1em;
    }
    .method3{
      font-weight: bold;
    }
  
      
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      .faqs {
          margin-top: 4rem;
          margin-left: 2em;
          margin-right: 2em;
        
        
          
        }
       
        .methoddiv{
          margin-top: 1em;
        }
        
    
        .layeronediv{
          text-align: center;
          width: 100%;
          margin-left: 1em;
          margin-bottom: 3em;
          display: none;
         
        }
        .layeronetitle p {
          text-align: center;
          color: #33AF4F;
          font-size: 24px;
          font-weight: bold;
          height: 1rem;
          /* margin-bottom: 3em; */
        }
  
        .layeronetitlewide p {
          text-align: center;
          color: #33AF4F;
          font-size: 40px;
          font-weight: bold;
          height: 1rem;
          margin-bottom: 2em;
          margin-top: 3em;
        
        
        }
        
        .dropdown{
          height:auto;
          border: solid 2px;
          border-color: gainsboro;
          border-radius: 10px;

          padding-top: .8em;
          padding-left:1em;
          margin-bottom: .5em;
          
        
      }

      .dropdownlast{
        height:auto;
        border: solid 2px;
        border-color: gainsboro;
        border-radius: 10px;

        padding-top: .8em;
        padding-left:1em;
        margin-bottom: 2em;
      
    }
      .mother{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: .5rem;
        /* border-bottom: 2px solid gainsboro; */
        /* background-color: black; */
        
      }
      .spandiv{
        flex-direction: row;
        width: 80%;
        
       
      }
      .widthdiv{
        width: 80%;
        
      }
      
    
      .imagediv{
        margin-right: .5em;
      
      }
      .dropdownContent{
        margin-top: 1rem;
        margin-right: .5em;
      }
      
    
      .spanindropdown{
        font-size: 18px;
        font-weight: 600;
        color: #33AF4F;
      }
      
      .dropdownContainer {
        position: relative; /* Set the parent container to relative positioning */
      }
      
      .line {
        margin-top: -1em;
        border-top: 2px solid gainsboro; /* Adjust the color and width as needed */
        width:100%;
        margin-left: -.3em;
        margin-bottom: .5em;
      }
      .descriptiondiv{
        /* background-color: black; */
        font-size: 16px;
        margin-left: .2em;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
      }
      .step {
        display: block;
       
        
       
      }
      .steps-container {
        width: 80%;
        margin: auto;
      }
      .step2{
        margin-bottom: 1em;
      }
      .step3{
        display: block;
       margin-left: 1em;
      }
      .method3{
        font-weight: bold;
      }
    
        
      }

      @media (min-width: 1025px) and (max-width: 1439px) {
        .faqs {
            margin-top: 4rem;
            margin-left: 6rem;
            margin-right: 4rem;   
           
          }

          
          .methoddiv{
            margin-top: 1em;
          }
          
      
          .layeronediv{
            text-align: center;
            width: 100%;
            margin-left: 1em;
            margin-bottom: 3em;
            display: none;
           
          }
          .layeronetitle p {
            text-align: center;
            color: #33AF4F;
            font-size: 24px;
            font-weight: bold;
            height: 1rem;
            /* margin-bottom: 3em; */
          }
    
          .layeronetitlewide p {
            text-align: center;
            color: #33AF4F;
            font-size: 40px;
            font-weight: bold;
            height: 1rem;
            margin-bottom: 2em;
            margin-top: 3em;
          
          
          }
          
          .dropdown{
            height:auto;
            border: solid 2px;
            border-color: gainsboro;
            border-radius: 10px;
           
            padding-top: .8em;
            padding-left:1em;
            margin-bottom: .5em;
            
          
        }

        .dropdownlast{
          height:auto;
          border: solid 2px;
          border-color: gainsboro;
          border-radius: 10px;
     
          padding-top: .8em;
          padding-left:1em;
          margin-bottom: 2em;
        
      }
        .mother{
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: .5rem;
          /* border-bottom: 2px solid gainsboro; */
          /* background-color: black; */
          
        }
        .spandiv{
          flex-direction: row;
          width: 80%;
          
         
        }
        .widthdiv{
          width: 80%;
          
        }
        
      
        .imagediv{
          margin-right: .5em;
        
        }
        .dropdownContent{
          margin-top: 1rem;
          margin-right: .5em;
        }
        
      
        .spanindropdown{
          font-size: 18px;
          font-weight: 600;
          color: #33AF4F;
        }
        
        .dropdownContainer {
          position: relative; /* Set the parent container to relative positioning */
        }
        
        .line {
          margin-top: -1em;
          border-top: 2px solid gainsboro; /* Adjust the color and width as needed */
          width:100%;
          margin-left: -.3em;
          margin-bottom: .5em;
        }
        .descriptiondiv{
          /* background-color: black; */
          font-size: 16px;
          margin-left: .2em;
          margin-top: 1.5em;
          margin-bottom: 1.5em;
        }
        .step {
          display: block;
          
          
         
        }
        .steps-container {
          width: 80%;
          margin: auto;
        }
        .step2{
          margin-bottom: 1em;
        }
        .step3{
          display: block;
         margin-left: 1em;
        }
        .method3{
          font-weight: bold;
        }
      
          
        }

        @media (min-width: 1440px) {
          .faqs {
              margin-top: 1rem;
              margin-left: 10rem;
              margin-right: 10rem;
              
              /* max-height: 35vw;
              overflow: scroll;    */
              
          
            }
            .methoddiv{
              margin-top: 1em;
            }
           
            
            
            .layeronediv{
              text-align: center;
              width: 100%;
              margin-left: 1em;
              margin-bottom: 3em;
              display: none;
             
            }
            .layeronetitle p {
              text-align: center;
              color: #33AF4F;
              font-size: 24px;
              font-weight: bold;
              height: 1rem;
              /* margin-bottom: 3em; */
            }
      
            .layeronetitlewide p {
              text-align: center;
              color: #33AF4F;
              font-size: 40px;
              font-weight: bold;
              height: 1rem;
              margin-bottom: 2em;
              margin-top: 3em;
            
            
            }
            
            .dropdown{
              height:auto;
              border: solid 2px;
              border-color: gainsboro;
              border-radius: 10px; 
              padding-top: .8em;
              padding-left:1em;
              margin-bottom: .5em;
              
            
          }

          .dropdownlast{
            height:auto;
            border: solid 2px;
            border-color: gainsboro;
            border-radius: 10px;
            padding-top: .8em;
            padding-left:1em;
            margin-bottom: 2em;
          
        }
          .mother{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-bottom: .5rem;
            /* border-bottom: 2px solid gainsboro; */
            /* background-color: black; */
            
          }
          .spandiv{
            flex-direction: row;
            width: 80%;
            
           
          }
          .widthdiv{
            width: 80%;
            
          }
          
        
          .imagediv{
            margin-right: .5em;
          
          }
          .dropdownContent{
            margin-top: 1rem;
            margin-right: .5em;
          }
          
        
          .spanindropdown{
            font-size: 18px;
            font-weight: 600;
            color: #33AF4F;
          }
          
          .dropdownContainer {
            position: relative; /* Set the parent container to relative positioning */
          }
          
          .line {
            margin-top: -1em;
            border-top: 2px solid gainsboro; /* Adjust the color and width as needed */
            width:100%;
            margin-left: -.3em;
            margin-bottom: .5em;
          }
          .descriptiondiv{
            /* background-color: black; */
            font-size: 16px;
            margin-left: .2em;
            margin-top: 1.5em;
            margin-bottom: 1.5em;
          }
          .step {
            display: block;
           
            
           
          }
          .steps-container {
            width: 80%;
            margin: auto;
          }
          .step2{
            margin-bottom: 1em;
          }
          .step3{
            display: block;
           margin-left: 1em;
          }
          .method3{
            font-weight: bold;
          }
          .scrollbutton{
            position: fixed;
            bottom: 20px;
            right: 20px; 
            z-index: 1000;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
          }
          .scrollbuttonfixed{
            position: absolute;
            bottom: -96em;
            right: 20px; 
            z-index: 1000;
            color: #fff;
            border: none;
            border-radius: 5px;
            padding: 10px 20px;
            cursor: pointer;
            font-size: 16px;
            transition: background-color 0.3s ease;
          }
          
        
            
          }



          
  
    
  
  
    
  


  
