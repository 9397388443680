* {
  font-family: 'Nunito Sans', sans-serif;
}

@media (max-width: 633px) {
  .navBarDownload1 button {
    font-weight: bold;
    font-size: 16px;
    border-radius: 14px;
    color: white;
    border: none;
    width: 100%;
    margin-top: 1rem;
    background-color:  #33AF4F;
  }
  .layerOneChild1 {
    display: none;
  }
  .layerTwoChild1 {
    display: none;
  }
  .webReso2 {
    display: none;
  }
  .webReso {
    display: none;
  }
  .Web {
    display: none;
  }
  .App {
    display: flex;
    flex-direction: column;
    width:100%;
  }
  .tablet3  {
    display: none;
  }
  .fifthLayerThree {
    display: none;
  }
  .carousel-image1 {
    display: none;
  }
  .tablet2 {
    display: none;
  }
  .wide {
    display: none;
  }
  .main {
   display: inline-block;
   width: 100%;
   position: fixed;
   padding-bottom: 0.5rem;
   z-index: 100;
   background-color: white;
   top: 0;
   box-shadow: 0px 1px 15px 2px #bdbdbd;
  }
  .backgroundFirst {
    margin-top: 2rem;
  }

  .firstDiv {
    display: flex;
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .navLinks {
    display: none;
  }
  .navBarDownload {
    display: none;
  }

  .menu span {
    height: 4px;
    width: 90%;
    background-color: #33AF4F;
    border-radius: 10px;
 
  }
  .imgNav img{
    width: 90px;

  }
.menu {
  display: flex;
  position: absolute;
  top: 1.1rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;
  height: 23px;
}
.menuClose {
  display: flex;
  position: absolute;
  top: 0.55rem;
  right: 1.5rem;
  flex-direction: column;
  justify-content: space-between;
  width: 2rem;

}
.menuClose img {
  height: 40px;
  width: 50px;
}

.burgerMenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: auto;
  padding-bottom: 2rem;
  background-color: white;

}

.burgerMenu span {
  color: #33AF4F;
  font-family: 'Nunito Sans', sans-serif;
  font-size:  24px;
}
.layerOne {
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  margin-top: 4rem;


}
.layerOneChild {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: center;
}

.layerOneChild p {
  color: #33AF4F;
  font-size: 24px;
  font-weight: bold;
  width: 80%;
  word-wrap: break-word;
}
.layerTwoChild {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  align-items: center;
  text-align: center;
}
.layerTwoChild p {
  margin-top: 1rem;
  color: #33AF4F;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  width: 80%;
  word-wrap: break-word;


}

.layerTwo img {
  width: 100%;


}
.layerTwo  {
  text-align: center;
}
.layerSecond{
  background-color: #1D2017;
  height: 3rem;
  text-align: center;
}

.layerSecond p{ 
  padding-top: 0.5rem;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 24px;

  padding-left: 1.5rem;

}
.thirdLayer  {
  margin-top: 1rem;
  text-align: center;
}
.thirdLayer p{
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  height: 1rem;
}
  .fourthMain {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
  }


.carousel-image {
  width: 80%;
 }

.fifthLayerTwo {
  background-color: #2D8240;
  height: 3rem;
}
.fifthLayerOne{
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-top: 2rem; 
  width: 86%;
}

.fifthLayer p {
  font-size: 24px;
  font-family: 'Nunito Sans', sans-serif;
  text-align: center;
  color: white;
  padding-top: 0.5rem;
}
.fifthLayer label {
  text-align: left;
  font-size: 18px;
  color: #33AF4F;
  font-weight: bold;
  font-family: 'Nunito Sans', sans-serif;
}

.fifthLayer span {
  margin-top: 1rem;
  text-align: left;
  font-size: 14px;
  color: black;
  font-family: 'Nunito Sans', sans-serif;
}
.fifthLayer img{
  width: 100%;
}
.active {
  text-decoration:none; /* Remove the underline */
}

.sixthLayer {
  background-color: #f5f5f5;
  width: 100%;

  display: inline-block;
}
.sixthLayerOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.sixthLayerOne label {
  color: #33AF4F;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 2rem;
}
.sixthLayerOne p {
  width: 80%;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;

}
.sixthLayerTwo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

}
.sixthLayerTwo img {
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Same value as width to make it a perfect circle */
  border-radius: 50%;

}

.sixthLayerThree {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.sixthLayerThree span{
  width: 80%;
  text-align: center;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.sixthLayerThree p{ 
  width: 60%;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;

}
.sixthLayerForth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.sixthLayerForth img {
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Same value as width to make it a perfect circle */
}
.sixthLayerForth span {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
}
.sixthLayerForth p { 
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  margin-top: 10px;
  width: 60%;
}
.sixthLayerFifth {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}
.sixthLayerFifth img {
  width: 80px; /* Adjust the size as needed */
  height: 80px; /* Same value as width to make it a perfect circle */
}
.sixthLayerFifth span {
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  width: 50%;
}
.sixthLayerFifth p { 
  font-family: 'Nunito Sans', sans-serif;
  font-size: 14px;
  margin-top: 10px;
  width: 50%;
}

.seventhLayer{
  display:flex;
  flex-direction: column;
  text-align: center;

}
.seventhLayerOne {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 2rem;


}
.seventhLayerOne p {
  height: 0.5rem;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 18px;
  color: white;
}
.seventhLayerTwo {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-left: 1.5rem;
}
.seventhLayerQR {
  margin-top: 2rem;
}
.seventhLayerQR img {
  width: 100px;
}
.seventhLayerThree img {
  width: 100px;

}

.seventhLayerFour img {
  width: 122px;


}
.seventhLayerFive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.seventhLayerFive p {
  font-size: 18px;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
  height: 0.6rem;
}
.seventhLayerFive span {
  font-size: 14px;
  color: white;
  font-family: 'Nunito Sans', sans-serif;
}

.seventhLayerSix {
  display: flex;
  flex-direction: column;
  justify-content: center;
} 

.seventhLayerSix img {
  width: 100%;
}

.footerLayer {
  background-color: #1D2017;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  padding-bottom: 2rem;
}

.footerLayerOne {
  margin-top: 1rem;
}
.footerLayerOne label {
  font-weight: bold;
  color: white;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
}
.footerLayerTwo {
  margin-top: 1rem;

}

.footerLayerTwo p {
  color: white;
  height: 0.5rem;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
}
.footerLayerThree{
  margin-top: 2rem;
}
.footerLayerThree p {
  color: white;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
}
.footerLayerFour span {
  color: white;
  font-size: 14px;
  font-family: 'Nunito Sans', sans-serif;
}
.footerLayerFive {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 1rem;
  margin-left: 2rem;
  
}
.footerLayerFive img {
  width: 20px;
  margin-right: 2rem;

}
.footerLayerSix {
  margin-top: 1rem;

}

.footerLayerSix p{
  height: 1rem;
  text-decoration: underline;
  color: white;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
}
.footerLayerSeven {
  margin-top: 1rem;

}
.footerLayerSeven p {
  color: white;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  height: 0.5rem;
}
.footerLayerEight{
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.footerLayerEight p {
  color: white;
  font-size: 12px;
  font-family: 'Nunito Sans', sans-serif;
  height: 0.5rem;
}
.links {
  text-decoration: none;
  margin-top: 20px;

}

.download {

}
.download button {
  background-color:  #33AF4F;
  color: white;
  border: none;
  width: 100%;
  font-weight: bold;
  font-size: 16px;
  margin-top: 20px;
  margin-right: 4rem;
  height: 2em;
  border-radius: 18px;
  cursor: pointer;

}

}





