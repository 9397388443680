@media (min-width: 769px) and (max-width: 1024px) {
  .layerOneChild1 {
    display: none;
  }
  .layerTwoChild1 {
    display: none;
  }
    .menuClose {
      display: none;
    }
    .seventhLayerQR {
      margin-bottom: 1rem;
    }
    .seventhLayerQR img {
      width: 100px;
    }
    .webReso2 {
      display: none;
    }
    .webReso {
      display: none;
    }
    .App {
      width: 100%;
  
    }
    .Web {
      display: none;
    }
    .main {
      display: inline-block;
     width: 100%;
     position: fixed;
     top: 0;
     z-index: 100;
     background-color: white;  
     box-shadow: 0px 1px 15px 2px #bdbdbd;
    }
    .firstDiv {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 2rem;
      margin-top: 1rem;
      margin-right: 2rem;
      padding-bottom: 0.5rem;
  
    }
    .imgNav {
      display: flex;
      flex-direction: row;
  
    }
    .imgNav img {
      width: 100px;
    }
    .navLinks  {

      margin-left: 1rem;
    }
    .navLinks span {
      color: #33AF4F;
      font-size: 18px;
      margin-right: 40px;
    }
  
    .navBarDownload button {
      font-weight: bold;
      font-size: 16px;
      border-radius: 14px;
      color: white;
      border: none;
      width: 100%;
      background-color:  #33AF4F;
    }
  
    .menu {
      display: none;
    }
    .firstDiv2 {
      display: none;
    }
    .backgroundFirst {
      display: flex;
      flex-direction: row;
      margin-left: 2rem;
      margin-right: 2rem;
  
      margin-top: 3rem;
    }
    .layerOne {
      width: 180%;
      display: flex;
      flex-direction: column;
    }
    .layerOneChild  {
      margin-top: 6rem;
    }
    .layerOneChild p{
  
      color: #33AF4F;
      font-size: 32px;
      font-weight: bold;

  
    }
    .layerTwoChild p {
      margin-top: 1rem;
      color: #33AF4F;
      font-family: 'Nunito Sans', sans-serif;
      font-size: 20px;
      font-weight: bold;
      width: 100%;

  
    
    }
  
    .layerTwo img {
      width: 100%;
    }
    .layerSecond {
      background-color: #1D2017;
      text-align: center;
      height: 4rem;
  
    }
  
    .layerSecond p {
      font-size: 24px;
      color: white;
      padding-top: 0.6rem;
    }
    .thirdLayer {
      text-align: center;
    }
    .mobile {
      display: none;
    }
    .wide {
        margin-top: 1rem;
    }
    .wide p{
      background-color: white;
      font-size: 16px;
      font-weight: bold;
    }
  
    .fourthMain {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      width: 100%;
    }
    .carousel-image {
     display: none;
    }
    .carousel-image1 {
      text-align: center;
      width: 80%;
    }
    
  .fifthLayer p {
    font-size: 24px;
    font-family: 'Nunito Sans', sans-serif;
    text-align: center;
    color: white;
    padding-top: 0.5rem;
  }
    .fifthLayer {
      margin-top: 2rem;
    }
    .fifthLayerTwo {
      background-color: #2D8240;
      height: 4rem;
    }
    .fifthLayerOne{
      display: none;
    }
  
    .fifthLayerThree {
      display: flex ;
    }
    .fifthLayerFourth {
      display: flex;
      flex-direction: column;
      width: 140%;
      margin-bottom: 2rem;
      margin-left: 2rem;
      margin-top: 8rem;
    }
    .fifthLayerFourth label{
      font-size: 24px;
      color: #33AF4F;
      font-weight: bold;
    }
  
    .fifthLayerFourth span{
      font-size: 14px;
      margin-top: 1rem;
    }
    .fifthLayerFourth {
  
    }
    .fifthLayerFifth img {
      width: 100%;
      margin-top: 2rem;
  
    }
    .sixthLayer {
      background-color: #f5f5f5;
      width: 100%;
      display: inline-block;
  
    }
    .sixthLayerOne {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  
    .sixthLayerOne label {
      font-size: 24px;
      color: #2D8240;
      font-weight: bold;
    }
    .sixthLayerOne p {
      font-size: 18px;
      width: 90%;
      font-weight: bold;
    }
    .sixthLayerTwo {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 2rem;

  
    }
    .sixthLayerTwo img {
      width: 100px;
    }
  
    .sixthLayerThree{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
    .sixthLayerThree span{
      font-size: 18px;
      font-weight: bold;
    }
    .sixthLayerThree p {
      margin-top: 2rem;
      width: 36%;
      font-size: 18px;
    }
  
    .sixthLayerForth {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
  
    .sixthLayerForth img {
      width: 100px;
    }
    .sixthLayerForth span {
      font-size: 14px;
      font-weight: bold;
      margin-top: 1rem;
    }
  
    .sixthLayerForth p {
      font-size: 14px;
      margin-top: 1rem;
      width: 38%;
    }
    .sixthLayerFifth {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-top: 1rem;
    }
  
    .sixthLayerFifth img {
      width: 100px;
    }
    .sixthLayerFifth span {
      font-size: 14px;
      font-weight: bold;
      margin-top: 1rem;
      width: 38%;
    }
    .sixthLayerFifth p {
      font-size: 14px;
      margin-top: 1rem;
      width: 38%;
    }
    .mobile2 {
      display: none;
    }
    .tablet2 {
      display: flex;
      flex-direction: row;
    }
  
    .seventhLayerOne {
      display: flex;
      flex-direction: column;
      text-align: center;
      width: 400px;
      margin-top: 4rem;
    }
  
    .seventhLayerOne p{
      font-size: 18px;
      color: white;
  
    }
    
    .handCellphone img {
      width: 500px;
    }
    .storeApp {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
    .seventhLayerThree img {
      width: 100px;
    }
    .seventhLayerFour img {
      width: 120px;
    }
    .seventhLayerFive {
        margin-top: 1rem;
    }
    .seventhLayerFive p {
      font-size: 12px;
      height: 0.2rem;
  
    }
    .seventhLayerFive span {
      font-size: 16px;
      color: white;
      height: 1rem;
    }
    .tablet3 {
      background-color: #1D2017;
      display: flex;
      flex-direction: column;
    }
    .firstLayerFooterV1{
      display: flex;
      flex-direction: row;
  
    }
  
    .layerV1 {
      display: flex;
      flex-direction: column;
      margin-left: 2rem;
      margin-top: 1rem;
      width: 550px;
    }
    .footerLayerOne label {
      font-size: 18px;
      color: white;
    }
    .footerLayerOne p {
      font-size: 12px;
      color: white;
      height: 0.2rem;
    }
  
    .footerLayerThree p{
      font-size: 12px;
      color: white;
      margin-top: 1rem;
    }
    .footerLayerFour span {
      font-size: 12px;
      font-weight: bold;
      color: white;
    }
  
    .footerLayerFive img {
      width: 20px;
      margin-right: 1rem;
    }
  
    .footLayerV3 {
      margin-top: 1rem;
  
      width: 150px;
    }
  
    .footLayerV3  {
      margin-top: 1rem;
    }
    .footLayerV3 label{
        font-size: 14px;
        color: white;
  
    }
    .footLayerV3 p{
      margin-top: 0.5rem;
      font-size: 12px;
      color: white;
      text-decoration: underline;
    }
    .footLayerV4 {
      margin-top: 1rem;
      width: 150px;
    }
    .footLayerV4 label{
      font-size: 14px;
      color: white;
  
  }
  .footLayerV4  p{
    margin-top: 0.5rem;
    font-size: 12px;
    color: white;
    text-decoration: underline;
  }
  
  .firstLayerFooterV2 {
    margin-top: 2rem;
    margin-left: 2rem;
  
  }
  .firstLayerFooterV2 p {
    font-size: 12px;
    color: white;
  }
  
  .active {
    text-decoration:none; /* Remove the underline */
  }
  .links {
    text-decoration: none;
  }
  
  }