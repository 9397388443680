
@media (max-width: 576px) {
  .terms{
    margin-top: 4em;
    margin-left: 2rem;
    margin-right: 2rem;
    
  }
  .mothercontent{
    margin-left: .39rem;
    margin-right: rem;

  }
  .layeronetitlewide{
    display: none;
    
  }
  .layeronediv{
    text-align: center;
    width: 100%;
    margin-bottom: 2em;
  }
  .layeronetitle p {
    text-align: center;
    color: #33AF4F;
    font-size: 24px;
    font-weight: bold;
    height: 1rem;
  }

  .layeronetitle2{
    /* text-align: center; */
    color: #33AF4F;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1em;
    width: 100%;
   
  }
  .layeronetitle3{
    color: black;
    font-size: 14px;
    width: 100%;
    margin-bottom: 1em;
  }
  
  }

  @media (min-width: 577px) and (max-width: 768px) {
    .terms{
      margin-top: 6em;
      margin-left: 2rem;
      margin-right: 2rem;
    
    }
    .mothercontent{
     
      margin-left: 1.5rem;
      margin-right: 1.5rem;
  
    }
    .layeronetitlewide p{
      text-align: center;
      color: #33AF4F;
      font-size: 32px;
      font-weight: bold;
      height: 1rem;
    }
    .layeronetitlewide{
     margin-bottom: 4em;
    }
    .layeronediv{
      text-align: center;
      width: 100%;
      margin-bottom: 2em;
      display: none;
    }
    
    .layeronetitle p {
      text-align: center;
      color: #33AF4F;
      font-size: 32px;
      font-weight: bold;
      height: 1rem;
      
    }
  
    .layeronetitle2{
      /* text-align: center; */
      color: #33AF4F;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 1em;
      width: 100%;
     
    }
    .layeronetitle3{
      color: black;
      font-size: 14px;
      width: 100%;
      margin-bottom: 1em;
    }
    
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      .terms{
        margin-top: 6em;
        margin-left: 2rem;
        margin-right: 2rem;
        
      }
      .mothercontent{
        margin-left: 0rem;
        margin-right: .5rem;
    
      }
      .layeronetitlewide p{
        text-align: center;
        color: #33AF4F;
        font-size: 40px;
        font-weight: bold;
        height: 1rem;
      }
      .layeronetitlewide{
       margin-bottom: 4em;
      }
      .layeronediv{
        text-align: center;
        width: 100%;
        margin-bottom: 2em;
        display: none;
      }
      
      .layeronetitle p {
        text-align: center;
        color: #33AF4F;
        font-size: 32px;
        font-weight: bold;
        height: 1rem;
        
      }
    
      .layeronetitle2{
        /* text-align: center; */
        color: #33AF4F;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1em;
        width: 100%;
       
      }
      .layeronetitle3{
        color: black;
        font-size: 16px;
        width: 100%;
        margin-bottom: 1em;
      }
      
      }

      @media (min-width: 1025px) and (max-width: 1439px) {
        .terms{
          margin-top: 6em;
          margin-left: 2rem;
          margin-right: 2rem;
          
        }
        .mothercontent{
          margin-left: 4rem;
          margin-right: 2.5em;
      
        }
        .layeronetitlewide p{
          text-align: center;
          color: #33AF4F;
          font-size: 40px;
          font-weight: bold;
          height: 1rem;
        }
        .layeronetitlewide{
         margin-bottom: 4em;
        }
        .layeronediv{
          text-align: center;
          width: 100%;
          margin-bottom: 2em;
          display: none;
        }
        
        .layeronetitle p {
          text-align: center;
          color: #33AF4F;
          font-size: 32px;
          font-weight: bold;
          height: 1rem;
          
        }
      
        .layeronetitle2{
          /* text-align: center; */
          color: #33AF4F;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 1em;
          width: 100%;
         
        }
        .layeronetitle3{
          color: black;
          font-size: 16px;
          width: 100%;
          margin-bottom: 1em;
        }
        
        }

        @media (min-width: 1440px) and (max-width: 1920px) {
          .terms{
            margin-top: 6em;
            margin-left: 2rem;
            margin-right: 2rem;
            
          }
          .mothercontent{
            margin-left: 9rem;
            margin-right: 8rem;
          }
          .layeronetitlewide p{
            text-align: center;
            color: #33AF4F;
            font-size: 40px;
            font-weight: bold;
            height: 1rem;
          }
          .layeronetitlewide{
           margin-bottom: 5em;
          }
          .layeronediv{
            text-align: center;
            width: 100%;
            margin-bottom: 2em;
            display: none;
          }
          
          .layeronetitle p {
            text-align: center;
            color: #33AF4F;
            font-size: 32px;
            font-weight: bold;
            height: 1rem;
            
          }
        
          .layeronetitle2{
            /* text-align: center; */
            color: #33AF4F;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 1em;
            width: 100%;
           
          }
          .layeronetitle3{
            color: black;
            font-size: 16px;
            width: 100%;
            margin-bottom: 1em;
          }
          
          }


  
