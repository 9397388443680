* {
  font-family: 'Nunito Sans', sans-serif;
}
@media (max-width: 576px) {
  .policy{
    margin-top: 7em;
    /* background-color: black; */
  }

  .layeronetitlewide{
    display: none;
  }

  .policymother{
    /* background-color: black; */
    margin-left: 1.5em;
    margin-right: 1.5em;
  }

  .layeronediv{
    /* margin-left: 6em; */
    margin-bottom: 2em;
    text-align: center;
  }
 
  .layeronetitle p {
    color: #33AF4F;
    font-size: 24px;
    font-weight: bold;
    margin-top: -1em;
    text-align: center;
   
  }

  .layeronetitle2{
    color: #33AF4F;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 1em;
    /* text-align: center; */
    margin-left: 1em;
    margin-right: 6em;
    width: 70%;
   
    

  }
  .layeronetitle3{
    color: black;
    font-size: 14px;
    margin-bottom: 1em;
    margin-left: 1.3em;
    margin-right: 6.8em;
    width: 90%;
   
    

  }
  
  }

  @media (min-width: 577px) and (max-width: 768px) {
    .policy{
      margin-top: 6em;
    
    }
  
    .policymother{
      /* background-color: black; */
      margin-left: 1em;
    
    }
    .layeronetitlewide p{
      color: #33AF4F;
      font-size: 32px;
      font-weight: bold;
      height: 1rem;
      text-align: center;
    }
    .layeronetitlewide{
     margin-bottom: 3em;
    }
  
    .layeronediv{
      display: none;
      /* margin-left: 6em; */
      margin-bottom: 2em;
      text-align: center;
    }
   
    .layeronetitle p {
      color: #33AF4F;
      font-size: 32px;
      font-weight: bold;
      margin-top: -1em;
      text-align: center;
     
    }
  
    .layeronetitle2{
      color: #33AF4F;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 1em;
      /* text-align: center; */
      margin-left: 1em;
      margin-right: 6em;
      width: 70%;
     
      
  
    }
    .layeronetitle3{
     
      color: black;
      font-size: 14px;
      margin-bottom: 1em;
      margin-left: 1.3em;
      width: 93%;
     
      
  
    }
    
    }

    @media (min-width: 769px) and (max-width: 1024px) {
      .policy{
        margin-top: 7em;
        /* background-color: black; */
      }
    
      .policymother{
        /* background-color: black; */
        margin-left: 1em;
        /* margin-right: 1.5em; */
      }

      .layeronetitlewide p{
        color: #33AF4F;
        font-size: 40px;
        font-weight: bold;
        height: 1rem;
        text-align: center;
      }
      .layeronetitlewide{
       margin-bottom: 5em;
      }
    
      .layeronediv{
        display: none;
        /* margin-left: 6em; */
        margin-bottom: 2em;
        text-align: center;
      }
     
      .layeronetitle p {
        color: #33AF4F;
        font-size: 40px;
        font-weight: bold;
        margin-top: -1em;
        text-align: center;
       
      }
    
      .layeronetitle2{
        color: #33AF4F;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 1em;
        /* text-align: center; */
        margin-left: 1em;
        margin-right: 6em;
        width: 70%;
       
        
    
      }
      .layeronetitle3{
        color: black;
        font-size: 16px;
        margin-bottom: 1em;
        margin-left: 1.3em;
        width: 94%;
       
        
    
      }
      
      }

      @media (min-width: 1025px) and (max-width: 1439px) {
        .policy{
          margin-top: 7em;
          /* background-color: black; */
        }
      
        .policymother{
          /* background-color: black; */
          margin-left: 4.9em;
          /* margin-right: 0em; */
        }

        .layeronetitlewide p{
          color: #33AF4F;
          font-size: 40px;
          font-weight: bold;
          height: 1rem;
          text-align: center;
        }
        .layeronetitlewide{
         margin-bottom: 5em;
        }
      
        .layeronediv{
          display: none;
          /* margin-left: 6em; */
          margin-bottom: 2em;
          text-align: center;
        }
       
        .layeronetitle p {
          color: #33AF4F;
          font-size: 40px;
          font-weight: bold;
          margin-top: -1em;
          text-align: center;
         
        }
      
        .layeronetitle2{
          color: #33AF4F;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 1em;
          /* text-align: center; */
          margin-left: 1em;
          margin-right: 6em;
          width: 70%;
         
          
      
        }
        .layeronetitle3{
          color: black;
          font-size: 16px;
          margin-bottom: 1em;
          margin-left: 1.3em;
          width: 90%;
        }
        
        }

        @media (min-width: 1440px) and (max-width: 1920px) {
          .policy{
            margin-top: 7em;
            /* background-color: black; */
          }
        
          .policymother{
            /* background-color: black; */
            margin-left: 9rem;
            margin-right: 1rem;
          }

          .layeronetitlewide p{
            color: #33AF4F;
            font-size: 40px;
            font-weight: bold;
            height: 1rem;
            text-align: center;
          }
          .layeronetitlewide{
           margin-bottom: 6em;
          }
        
          .layeronediv{
            display: none;
            /* margin-left: 6em; */
            margin-bottom: 2em;
            text-align: center;
          }
         
          .layeronetitle p {
            color: #33AF4F;
            font-size: 40px;
            font-weight: bold;
            margin-top: -1em;
            text-align: center;
          }
        
          .layeronetitle2{
            color: #33AF4F;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 1em;
            /* text-align: center; */
            margin-left: 1em;
            margin-right: 6em;
            width: 70%;
           
            
        
          }
          .layeronetitle3{
            color: black;
            font-size: 16px;
            margin-bottom: 1em;
            margin-left: 1.3em;
            margin-right: 6.8em;
            width: 90%;
           
            
        
          }
          
          }


  